<template>
  <p-modal
    :title="$t('modal.bulkUser.title')"
    size="md"
    ref="bulkUserModal"
    modal-id="bulkUserModal"
    @close="onClose"
    @hide="onClose"
  >
    <template #modal-content>
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(importUploadBulkUser)">
          <ValidationProvider rules="required" v-slot="{ errors }" mode="passive">
            <b-form-group :label="$t('button.search')" label-for="ipt_file">
              <b-input-group>
                <b-form-input
                  id="ipt_file"
                  name="ipt_file"
                  aria-describedby="aria-file"
                  readonly
                  :state="errors[0] ? false : null"
                  v-model="filename"
                  @click="onChange()"
                />
                <b-input-group-append>
                  <b-button variant="outline-secondary" @click="onChange()">
                    <b-icon icon="search" font-scale="0.9" shift-v="2"/>
                  </b-button>
                </b-input-group-append>
                <b-form-invalid-feedback id="aria-file">
                  {{ errors[0] }}
                </b-form-invalid-feedback>

                <label for="fileinput" class="d-none">
                  {{ $t('button.search') }}
                  <input id="fileinput"
                         name="fileinput"
                         ref="fileinput"
                         type="file"
                         @change="onFilePicked"
                         multiple
                         class="d-none">
                </label>
              </b-input-group>
            </b-form-group>
          </ValidationProvider>
          <b-row class="mt-4">
            <b-col md="12" class="text-right">
              <b-button @click="downloadTemplate()">
                {{ $t('modal.bulkUser.download') }}
              </b-button>
              <b-button variant="primary" class="ml-md-2 mt-2 mt-md-0" type="submit">
                {{ $t('modal.bulkUser.import') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </template>
  </p-modal>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import PModal from '@/components/PModal.vue';

export default {
  name: 'bulk-user-modal',
  components: {
    ValidationObserver,
    PModal,
  },
  data() {
    return { filename: '', file: null };
  },
  methods: {
    onChange() {
      this.$refs.fileinput.click();
    },
    onFilePicked(event) {
      const { files } = event.target;
      this.filename = files && files.length ? files[0].name : '';
      [this.file] = files;
    },
    downloadTemplate() {
      this.$api.downloadBulkUserTemplate().then((response) => {
        if (response) {
          const url = window.URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = url;
          const filename = 'bulk-upload-template.xlsx';
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
        }
      });
    },
    importUploadBulkUser() {
      const formData = new FormData();
      formData.append('file', this.file);
      this.$api.bulkUserUpload(formData).then((response) => {
        if (response) {
          this.$bvModal.hide('bulkUserModal');
          this.$swal({
            title: this.$t('modal.bulkUser.success.title'),
            html: this.$t('modal.bulkUser.success.description'),
            type: 'success',
            confirmButtonText: this.$t('button.ok'),
          });
        } else if (response === false) {
          this.$swal({
            title: this.$t('modal.bulkUser.template-error.title'),
            html: this.$t('modal.bulkUser.template-error.description'),
            type: 'error',
            confirmButtonText: this.$t('button.ok'),
          });
        } else {
          this.$swal({
            title: this.$t('modal.bulkUser.error.title'),
            html: this.$t('modal.bulkUser.error.description'),
            type: 'error',
            confirmButtonText: this.$t('button.ok'),
          });
        }
      });
    },
    onClose() {
      this.file = null;
      this.filename = '';
    },
  },
};
</script>
<style lang="scss" scoped>
#ipt_file {
  cursor: pointer;
  background-color: inherit;
}
</style>

import Vue from 'vue';
import { ValidationProvider, extend, configure } from 'vee-validate';
import {
  // eslint-disable-next-line camelcase
  required, email, min, max, confirmed, alpha_spaces, regex,
} from 'vee-validate/dist/rules';
import i18n from './i18n';

configure({
  defaultMessage: (field, values) => i18n.t(`validation.messages.${values._rule_}`, values),
});

extend('required', required);
extend('email', email);
extend('min', min);
extend('max', max);
extend('confirmed', confirmed);
extend('alpha_spaces', alpha_spaces);
extend('regex', regex);

extend('phone', {
  validate: (value) => {
    const patt = /^[+]?[(]?\d{1,3}\)?[-\s./0-9]*$/g;
    return patt.test(value);
  },
  message: () => i18n.t('errorValidator.phone'),
});

Vue.component('ValidationProvider', ValidationProvider);

import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('./locale', true, /[\w-,\s]+\.json$/i);
  const messages = {};
  locales.keys()
    .forEach((key) => {
      const matched = key.match(/([\w-]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = locales(key);
      }
    });
  return messages;
}

function getLocale() {
  const localeRegex = /(^|;) ?LOCALE=([^;]*)(;|$)/;
  const cookie = localeRegex.exec(document.cookie);
  const langs = ['de', 'es', 'en', 'pt'];

  if (cookie?.[2] && langs.includes(cookie[2])) {
    return cookie[2];
  }

  if (navigator.language && langs.includes(navigator.language)) {
    return navigator.language;
  }

  if (navigator.userLanguage && langs.includes(navigator.userLanguage)) {
    return navigator.userLanguage;
  }

  return process.env.VUE_APP_I18N_LOCALE;
}

export default new VueI18n({
  locale: getLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
});
